
import { defineComponent, inject, toRefs } from 'vue';
import { IonContent, IonPage } from '@ionic/vue';
import { shopOrders } from '@/services/firebase';
import { Store } from '@/stores';
import HeaderTabs from '@/components/Header/HeaderTabs.vue';
import MessagesBanner from '@/components/Messages/MessagesBanner.vue';
import OrdersTable from '@/components/OrdersTable/OrdersTable.vue';

// const minutesToShowOrdersAfterPickup = 5;/

export default defineComponent({
    name: 'Orders',

    components: {
        IonContent,
        IonPage,
        HeaderTabs,
        MessagesBanner,
        OrdersTable,
    },

    setup() {
        const store = inject('store') as Store;
        const { showPickup } = toRefs(store.state);

        return {
            showPickup,
            shopOrders,
        };
    },
});
